body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141414;
  color: #fff !important;
}

h1, h2, h3, h4 {
  color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main_page .ant-select-open .ant-select-selection-item {
  opacity: .08 !important;
  color: #141414 !important;
}

svg .white {
  fill: #fff !important;
  stroke: #fff !important;
}

.form-control {
  background-color: #141414 !important;
  border-radius: 2px !important;
  border: 1px solid #1C8FFF !important;
  font-size: 16px !important;
  color: #fff !important;
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  box-sizing: border-box !important;
}

.form-control {
  height: 42px !important;
}

.ml-form-successContent p, .ml-form-successContent h4 {
  text-align: center !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon svg{
  color: rgba(255, 255, 255, 0.1) !important;
}
